<template>
  <!-- 热门资讯页 -->
  <div class="bg">
    <div class="container">
      <div class="banner">
        <span>当前位置：<router-link to="/" class="bread">众惠首页</router-link> &gt;</span>
        <span style="color: #6b6b6b">资讯</span>
      </div>
      <div class="middle">
        <div class="list">
          <div class="active btn">热门资讯</div>
          <router-link to="/fundSchool" class="btn">基金学堂</router-link>
          <router-link to="/activeList" class="btn">活动列表</router-link>
        </div>
        <div class="content">
          <div v-for="item in mydata" :key="item.infoId" class="box">
            <router-link :to="'detail?infoId='+item.infoId">
              <img :src="item.webCoverUrl" alt />
            </router-link>
            <div class="article-content">
              <router-link :to="'detail?infoId='+item.infoId">
                <div class="box-h2">{{ item.title }}</div>
              </router-link>             
              <div class="subtitle box-p">{{ item.summary }}</div>
              <div class="flex bottom">
                <p>{{ item.publishTime }}</p>
                <router-link :to="'detail?infoId='+item.infoId">
                  <div class="more">
                    <span>更多</span>
                    <span class="iconfont icon-a-fundmarket-more" />
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <pagination
          v-if="total > 0"
          :total="total"
          :page.sync="pageNum"
          :limit.sync="pageSize"
          :layout="layout"
          @pagination="getList"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { fundSchool } from '@/api/activeList'

export default {
  data() {
    return {
      mydata: [],
      layout: 'prev, pager, next,sizes',
      total: 10,
      pageNum: 1,
      pageSize: 10
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      fundSchool({
        infoType: '0',
        publishStatus: '1',
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }).then((res) => {
        if (res.code === 200) {
          this.mydata = res.data.data
          // this.pageTotal = Math.ceil(res.data.data.length / 10)
          this.total = res.data.total
        }
      })
    }
  }
}
</script>

<style scoped>
.bg {
  background-color: #f3f4f6;
  margin-bottom: -30px;
}
.container {
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 40px;
  position: relative;
}
.banner {
  height: 16px;
  width: 248px;
  text-align: left;
  color: #a5a5a5;
  font-size: 14px;
  padding-top: 24px;
  padding-bottom: 24px;
  line-height: 16px;
}
.middle {
  height: 100%;
  background-color: #fff;
  padding: 40px;
}
.list {
  display: flex;
  margin-bottom: 40px;
}
.btn {
  height: 50px;
  width: 124px;
  border: 1px solid #fff;
  font-size: 18px;
  color: #a5a5a5;
  text-align: center;
  line-height: 50px;
  margin-right: 24px;
}
.bread{
  color: #a5a5a5;
}
.active {
  border: 1px solid #ce9b63;
  border-radius: 26px;
  color: #ce9b63 !important;
  font-weight: 700;
  font-size: 18px;
}
.content {
  width: 1120px;
  display: flex;
  flex-wrap: wrap;
}

.article-content {
  padding: 18px 16px 24px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.subtitle {
  margin: 30px 0 46px;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
.bottom {
  justify-content: space-between;
}
.more {
  font-size: 12px;
  line-height: 12px;
  color: #8691a8;
  cursor: pointer;
}
.icon-a-fundmarket-more {
  margin-left: 10px;
  font-size: 12px;
}
.box {
  display: flex;
  flex-direction: column;
  /* flex: 1; */
  width: 32%;
  border-radius: 5px;
  box-shadow: 0px 4px 4px 0px rgba(192, 189, 189, 0.25);
  margin:0 7px 24px 7px;
}
.box img {
  /* width: 357px; */
  width: 100%;
  height: 180px;
}
.box-h2 {
  font-size: 18px;
  font-weight: 700;
  /* margin-left: 16px; */
  /* margin-top: 18px; */
  display: block;
  color: #25293d;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.box-p,
.box p {
  /* margin-top: 30px; */
  /* margin-left: 16px; */
  font-size: 14px;
  color: #8691a8;
  color: #8691a8;
}
</style>