import request from '@/utils/request'

// 活动列表
export function eventList(data) {
  return request({
    url: '/api/system/event/eventList',
    method: 'post',
    data
  })
}

// 基金学堂/资讯
export function fundSchool(data) {
  return request({
    url: '/api/sale/platfcfg/info/page',
    method: 'post',
    data
  })
}
// 获取资讯详细信息
export function getInfoFind(data) {
  return request({
    url: '/api/sale/platfcfg/info/find',
    method: 'post',
    data
  })
}

// 查询【销售端配置图像】分页列表 ,基金学堂轮播图
export function getImgPage(data) {
  return request({
    url: '/api/sale/platfcfg/img/page',
    method: 'post',
    data
  })
}

// 查询活动详情
export function getEventInfo(data) {
  return request({
    url: '/api/system/event/get',
    method: 'post',
    data
  })
}

